import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsLeftRight } from '@fortawesome/pro-regular-svg-icons/faArrowsLeftRight';

import ScreenReaderText from '../screen-reader-text';
import { shake } from 'libs/animations';
import Spacing from 'layouts/Spacing';

//#region
const OuterWrapper = styled.div`
	position: relative;

	${p =>
		p.size === 'large' &&
		css`
			@media only screen and (min-width: 1640px) {
				max-width: ${p => p.theme.widths.giga};
				width: ${p => p.theme.widths.giga};
				position: relative;
				left: 50%;
				right: 50%;
				margin-left: ${p => `calc(-${p.theme.widths.giga} / 2)`};
				margin-right: ${p => `calc(-${p.theme.widths.giga} / 2)`};
			}
		`}
`;

const Wrapper = styled.div`
	width: 100%;
	border-radius: ${p => p.theme.utils.borderRadius};
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	max-height: 100vh;

	${p =>
		p.theme.media.smallOnly(css`
			font-size: 15px;
			line-height: 27px;
		`)}
`;

const Gradient = styled.div`
	position: absolute;
	right: 0px;
	top: 0px;
	width: 200px;
	z-index: 3;
	border-radius: ${p =>
		`0 ${p.theme.utils.borderRadius} ${p.theme.utils.borderRadius} 0`};
	background: linear-gradient(
		90deg,
		rgba(40, 41, 44, 0) 0%,
		rgba(40, 41, 44, 0.75) 100%
	);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 30px;
	opacity: ${p => (p.$hide ? 0 : 1)};
	transition: opacity 350ms ease;
	pointer-events: none;
	${p =>
		p.theme.media.smallOnly(css`
			width: 100px;
			padding-right: 20px;
		`)}
`;

const Icon = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 30px !important;
	background: ${p => p.theme.colors.white};
	box-shadow: ${p => p.theme.utils.boxShadowSoft};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	animation: ${shake} 4.72s ease infinite;
	transform-origin: 50% 50%;
	backface-visibility: hidden;
	perspective: 1000px;
`;

const TableWrap = styled.table`
	width: 100%;
	border-spacing: 0;
	border-radius: ${p => p.theme.utils.borderRadius};

	thead {
		border: 1px solid ${p => p.theme.colors.blue600};
		position: sticky;
		top: 0;
		tr {
			border: none;
			background-color: ${p => p.theme.colors.blue600} !important;
			color: white;
			p {
				margin: 0;
				font-size: 17px;
				line-height: 29px;
				font-weight: 500;
				color: white !important;
				${p =>
					p.theme.media.smallOnly(css`
						font-size: 15px;
						line-height: 27px;
					`)}
			}
			td {
				background-color: ${p => p.theme.colors.blue600} !important;
				font-weight: 500;
			}
		}
	}

	tbody {
		${p =>
			!p.$hasHeader &&
			css`
				tr:first-of-type {
					td {
						border-top: 1px solid ${p => p.theme.colors.grey400};
						&:first-of-type {
							border-radius: ${p =>
								`${p.theme.utils.borderRadius} 0 0 0`};
						}
						&:last-of-type {
							border-radius: ${p =>
								`0 ${p.theme.utils.borderRadius} 0 0`};
						}
					}
				}
			`}

		tr {
			&:nth-child(odd) {
				background-color: ${p => p.theme.colors.grey200};
			}
			td {
				min-width: 150px;
				white-space: break-spaces;
				border-bottom: 1px solid ${p => p.theme.colors.grey400};
				&:not(:first-of-type) {
					white-space: nowrap !important;
				}
				&:first-of-type {
					border-left: 1px solid ${p => p.theme.colors.grey400};
				}
				&:last-of-type {
					border-right: 1px solid ${p => p.theme.colors.grey400};
				}
			}
			&:last-of-type {
				td {
					&:first-of-type {
						border-radius: ${p =>
							`0 0 0 ${p.theme.utils.borderRadius}`};
					}
					&:last-of-type {
						border-radius: ${p =>
							`0 0 ${p.theme.utils.borderRadius} 0`};
					}
				}
			}
		}
	}

	td {
		padding: 10px 15px;
		white-space: nowrap;
		border: none;
		${p =>
			p.theme.media.XSmallOnly(css`
				padding: 5px 10px;
			`)}
		p {
			margin: 0;
		}
	}

	> tr,
	tbody > tr {
		&:nth-child(odd) {
			background-color: ${p => p.theme.colors.grey200};
		}
		td {
			min-width: 150px;
			white-space: break-spaces;
			border-bottom: 1px solid ${p => p.theme.colors.grey400};
			&:not(:first-of-type) {
				white-space: nowrap !important;
			}
			&:first-of-type {
				border-left: 1px solid ${p => p.theme.colors.grey400};
			}
			&:last-of-type {
				border-right: 1px solid ${p => p.theme.colors.grey400};
			}
		}
	}
`;

//#endregion

/**
 * A React component that displays a table.
 *
 * @component
 * @param {object} props - The properties that define the component.
 * @param {boolean} [props.hasHeader=true] - Indicates whether the table has a header.
 * @param {string} [props.size='default'] - The size of the table. Can be 'default' or 'large'.
 * @param {ReactNode} props.children - The child elements to be displayed in the table.
 * @param {object} props - The properties that define the component.
 * @returns {ReactNode} The Table component.
 */
export default function Table({
	hasHeader = true,
	size = 'default',
	children,
	...props
}) {
	const tableRef = useRef(null);
	const tableId = `table-${Math.floor(Math.random() * 100)}`;
	const [tableTitle, setTableTitle] = useState(props?.title || 'Tabell');
	const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
	const [hideGradient, setHideGradient] = useState(false);

	/**
	 * Handles the scroll event on the table.
	 * If the table is scrolled horizontally, it hides the gradient.
	 * @param {Event} e - The scroll event.
	 */
	const handleScroll = e => {
		if (!e?.target) return;
		if (e.target.scrollLeft > 0) {
			setHideGradient(true);
		} else {
			setHideGradient(false);
		}
	};

	/**
	 * A React effect hook that updates the table title.
	 * It gets the text of the previous sibling of the table and sets it as the table title.
	 * The effect runs when the tableId changes.
	 */
	useEffect(() => {
		if (!document?.getElementById(tableId)?.previousSibling) return;
		let previousNodeText =
			document?.getElementById(tableId)?.previousSibling?.textContent;
		if (previousNodeText.endsWith(':')) {
			previousNodeText = previousNodeText.slice(0, -1);
		}
		if (previousNodeText.length) setTableTitle(previousNodeText);
	}, [tableId]);

	/**
	 * A React effect hook that checks if the table has horizontal scroll.
	 * It compares the width of the table with the width of the wrapper.
	 * If the table's width is greater, it sets hasHorizontalScroll to true.
	 * The effect runs when the tableRef changes.
	 */
	useEffect(() => {
		const wrapperWidth = (size === 'large' && 1600) || 1200;
		if (tableRef?.current.offsetWidth > wrapperWidth) {
			setHasHorizontalScroll(true);
		}
		//eslint-disable-next-line
	}, [tableRef?.current]);

	if (!children) return <></>;

	return (
		<Spacing spacing={props?.spacing}>
			<OuterWrapper size={size}>
				<Wrapper
					aria-label={tableTitle}
					aria-describedby={`${tableId}-desc`}
					id={tableId}
					aria-rowcount={children?.length}
					onScroll={handleScroll}>
					<ScreenReaderText id={`${tableId}-desc`}>
						{tableTitle}
					</ScreenReaderText>

					<TableWrap
						ref={tableRef}
						className="table"
						$hasHeader={hasHeader}>
						{children}
					</TableWrap>
				</Wrapper>
				{hasHorizontalScroll && size === 'large' && (
					<Gradient
						$hide={hideGradient}
						aria-label="Indikerer at tabellen har horisontal scroll">
						<Icon>
							<FontAwesomeIcon icon={faArrowsLeftRight} />
						</Icon>
					</Gradient>
				)}
			</OuterWrapper>
		</Spacing>
	);
}
